import React from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'

interface Node {
  node: {
    frontmatter: {
      posts: {
        id: number
        slug: string
        title: string
        date: string
        snippet: string
        description: string
        image: {
          src: any
          alt: string
        }
      }
    }
  }
}

interface Slug {
  slug: string
}

const s = {
  container: `w-full h-fit`,
  title: `-mt-4 mb-12 text-h3`,

  card: `flex flex-col mb-20 mdx:last:mb-0`,
  link: `w-full h-full flexcenter flex-col underline transition-all delay-300 ease-out duration-500 hover:underlinered smx:flex-col`,

  image: `w-full h-h35 mdm:h-h65 smm:h-h50 xsx:h-h32`,

  body: `w-full h-fit flex flex-col pt-12 text-black`,
  subtitle: `-mt-4 -mb-2 text-clamp-2xl`,
  date: `mb-4 text-sm`,
  description: `text-lg leading-10`,
}

const Related = ({ slug }: Slug) => {
  const datas = useStaticQuery(graphql`
    query {
      allMarkdownRemark(
        limit: 4
        filter: { fileAbsolutePath: { regex: "/posts/" } }
        sort: { fields: frontmatter___posts___date, order: DESC }
      ) {
        edges {
          node {
            frontmatter {
              posts {
                id
                slug
                title
                date
                snippet
                image {
                  alt
                  src {
                    childImageSharp {
                      gatsbyImageData(
                        layout: FULL_WIDTH
                        quality: 80
                        placeholder: DOMINANT_COLOR
                        formats: [AUTO, WEBP]
                      )
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  const posts = datas.allMarkdownRemark.edges.filter(
    ({ node }: Node) => node.frontmatter.posts.slug !== slug
  )

  return (
    <section className={s.container}>
      <h3 className={s.title}>Related</h3>

      {posts.map(({ node }: Node) => {
        const { id, slug, title, date, snippet, image } = node.frontmatter.posts

        return (
          <div key={id} className={s.card}>
            <Link className={s.link} to={`/post/${slug}/`}>
              <GatsbyImage
                className={s.image}
                image={image.src.childImageSharp.gatsbyImageData}
                alt={image.alt}
                loading="eager"
              />
              <div className={s.body}>
                <h3 className={s.subtitle}>{title}</h3>
                <p className={s.date}>{date}</p>
                <p className={s.description}>{snippet}...</p>
              </div>
            </Link>
          </div>
        )
      })}
    </section>
  )
}

export default Related
