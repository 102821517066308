import React, { useEffect } from 'react'
import { graphql } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'

import Seo from 'shared/Seo/Seo'
import Layout from 'shared/Layout/Layout'
import Pagination from 'shared/Pagination/Pagination'
import Related from 'main/Post/Related'

interface Datas {
  pageContext: any
  data: {
    markdownRemark: {
      html: any
      frontmatter: {
        posts: {
          slug: string
          title: string
          date: string
          snippet: string
          image: {
            alt: string
            src: any
          }
        }
      }
    }
  }
}

const s = {
  container: `w-full h-fit mt-60 mx-0 mb-40`,
  wrapper: `wrapper`,
  grid: `grid-half gap-24 mdx:grid-cols-1 mdx:gap-36`,

  card: `w-full h-fit`,
  body: `w-full h-fit flex flex-col mb-12`,
  title: `-mb-2 text-h3`,
  date: `-mb-4 text-sm`,
  description: `text-justify post`,

  image: `w-full h-h55 mb-12 smm:h-h40 xsx:h-h32`,
}

const Post = ({ data, pageContext }: Datas) => {
  const { prevPost, nextPost } = pageContext
  const { slug, title, date, snippet, image } =
    data.markdownRemark.frontmatter.posts

  useEffect(() => {
    linkHighlight()
  })

  const linkHighlight = () => {
    const links = document.querySelectorAll('.link')

    links.forEach((link: any) => {
      link.classList.contains('blog') && link.classList.add('highlight')
    })
  }

  return (
    <Layout backgrounds="bg-black">
      <Seo title={title} description={snippet} />
      <section className={s.container}>
        <div className={s.wrapper}>
          <div className={s.grid}>
            <div className={s.card}>
              <GatsbyImage
                className={s.image}
                image={image.src.childImageSharp.gatsbyImageData}
                alt={image.alt}
                loading="eager"
              />
              <div className={s.body}>
                <h3 className={s.title}>{title}</h3>
                <p className={s.date}>{date}</p>

                <div
                  className={s.description}
                  dangerouslySetInnerHTML={{ __html: data.markdownRemark.html }}
                />
              </div>
              <Pagination page={pageContext} prev={prevPost} next={nextPost} />
            </div>
            <Related slug={slug} />
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default Post

export const query = graphql`
  query ($slug: String!) {
    markdownRemark(
      fileAbsolutePath: { regex: "/posts/" }
      frontmatter: { posts: { slug: { eq: $slug } } }
    ) {
      html
      frontmatter {
        posts {
          slug
          title
          date
          snippet
          image {
            alt
            src {
              childImageSharp {
                gatsbyImageData(
                  layout: FULL_WIDTH
                  quality: 80
                  placeholder: DOMINANT_COLOR
                  formats: [AUTO, WEBP]
                )
              }
            }
          }
        }
      }
    }
  }
`
